import React, { useState, useContext } from "react";
import { Form } from "react-bootstrap";
import { Input, TextArea, Button } from "../Core";
import GlobalContext from "../../context/GlobalContext";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const ContactForm = ({ theme = "dark", ...rest }) => {

  const gContext = useContext(GlobalContext);

  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }));
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    makeApiCall(inputs);
    
  }

  const makeApiCall = (data) => {
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(data)
    };

    fetch('https://us-central1-email-6139f.cloudfunctions.net/sendEmail', requestOptions)
      .then(response => {
        if (response.status === 200) {
          // gContext.toggleContact();
          toast.success("Message Sent!");
          setInputs({});
          
        }
        else {
          // gContext.toggleContact();
          toast.error("Uh Oh! Try again.");
        }
      }).catch(err => {
        // gContext.toggleContact();
        toast.error("Uh Oh! Try again.");
      })
  }

  return (
    <Form onSubmit={handleSubmit}
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      {...rest}
    >
      {/* You still need to add the hidden input with the form name to your JSX form */}
      <input type="hidden" name="contact" value="contact" />
      <div className="mt-4">
        <Input type="text" placeholder="Your name" required name="name" value={inputs.name || ""} onChange={handleChange} />
      </div>
      <div className="mt-4">
        <Input type="email" placeholder="Email address" required name="email" value={inputs.email || ""} onChange={handleChange} />
      </div>
      <div className="mt-4">
        <Input type="text" placeholder="Subject" required name="subject" value={inputs.subject || ""} onChange={handleChange} />
      </div>
      <div className="mt-4 ">
        <TextArea
          rows={4}
          placeholder="Write your message"
          required
          name="message"
          value={inputs.message || ""} onChange={handleChange}
        />
      </div>
      <div className="mt-4 mt-lg-5">
        <Button arrowRight variant="primary" type="submit">
          SEND
        </Button>
      </div>
    </Form>
  );
};

export default ContactForm;
